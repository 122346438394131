import { UpdateTradeSettingsDto } from '@core/models';
import { Box, Button, Card, CardActions, CardOverflow, Divider, FormControl, FormHelperText, FormLabel, Input, Stack, Switch, Typography } from '@mui/joy';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Account, TradeSettings as TradeSettingsModel } from 'store/domain/account/account';
import { FollowedTrader } from 'store/domain/leaderboard/followed-trader';

export type AccountSettingsProps = PropsWithChildren<{ account: Account; }>

export const AccountSettings = observer(({ account }: AccountSettingsProps) => {
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
  } = useForm<any>({ defaultValues: { accountName: account.name } });

  const onSubmit: SubmitHandler<{ accountName: string }> = async data => {
    await account.updateName(data.accountName);
    reset({ accountName: account.name });
  };

  useEffect(() => {
    reset({ accountName: account.name });
  }, [account]);

  const sx = {
    width: 300,
    justifyContent: 'space-between',
    gap: 3,
  };

  return (
    <Card>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ mb: 2 }}>
          <Typography level="title-md">Account settings</Typography>
        </Box>
        <Divider />
        <Stack direction="row" spacing={4} paddingY={3}>
          <Stack spacing={4} >
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Account name</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Useful label for account</FormHelperText>
              </div>
              <Input {...register('accountName')} type="text" slotProps={{ input: { step: 0.1 } }} required />
            </FormControl>
          </Stack>
        </Stack>
        <CardOverflow sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
        >
          <CardActions sx={{
            alignSelf: 'flex-end',
            pt: 2,
            paddingBottom: 0,
          }}
          >
            <Button size="md" variant="solid" type="submit" disabled={!formState.isDirty} loading={false}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      </form>
    </Card>
  );
});
