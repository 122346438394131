import { EventType } from '@core/models';
import { KeyboardArrowDown, KeyboardArrowUp, SyncDisabledOutlined, SyncOutlined } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, List, ListItem, ListSubheader, Sheet, Table } from '@mui/joy';
import { Link } from 'components/Link';
import { ExtraInfoCard } from 'components/ExtraInfoCard/ExtraInfoCard';
import { Symbol } from 'components/Symbol';
import { observer } from 'mobx-react-lite';
import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { useStore } from 'store/StoreContext';
import { CopyTradeOrder, TradeEvent } from 'store/domain/trade-events/trade-events';


export const TradeEventTable = observer(() => {
  const { tradeEvents } = useStore();

  useEffect(() => {
    tradeEvents.fetchEvents(true);
  }, [tradeEvents]);

  return (
    <>
      <Sheet sx={{ paddingBottom: 4 }}>
        <Table size="lg">
          <thead>
            <tr>
              <th style={{ width: 40 }} aria-label="empty" />
              <th>Symbol</th>
              <th>Trader</th>
              <th>Type</th>
              <th>Detail</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {tradeEvents.eventsByDay.map(([dateString, eventList]) => (
              <Fragment key={dateString}>
                <tr>
                  <td colSpan={6}>
                    <ListSubheader sx={{ marginLeft: 1 }}>
                      {dateString}
                    </ListSubheader>
                  </td>
                </tr>
                {eventList.map(event => <Row key={event.id} event={event} />)}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: 3,
        marginBottom: 4,
      }}
      >
        <Button
          disabled={!tradeEvents.hasMore}
          onClick={() => tradeEvents.fetchEvents()}
          size="lg"
          variant="soft"
        >
          Load more
        </Button>
      </Box>
    </>
  );
});


export const Row = observer(({ event: { trader, copyTradeOrders, symbol, type, amountChange, markPrice, percentageChange, leverage, pnl, formattedRoe, amount, long, formattedDate, isProfitable } }: PropsWithChildren<{ event: TradeEvent }>) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <tr>
        <td>
          <IconButton
            aria-label="expand row"
            variant="plain"
            color="neutral"
            size="sm"
            onClick={() => setOpen(!isOpen)}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </td>
        <td>
          <Symbol symbol={symbol} leverage={leverage} isLong={long} />
        </td>
        <td>
          <Link to={`/main/trader-positions/${trader.id}`} underline="none" color="neutral">
            {trader.name}
          </Link>
        </td>
        <td>
          <Chip color={type === EventType.INCREASE || type === EventType.OPEN ? 'success' : 'danger'}>
            {type}
          </Chip>
        </td>
        <td>
          {type === EventType.CLOSE && <ExtraInfoCard title="ROE" description={formattedRoe} descriptionColor={isProfitable ? 'success' : 'danger'} />}
          {(type === EventType.INCREASE || type === EventType.DECREASE) && <ExtraInfoCard title="Change" description={percentageChange} />}
        </td>
        <td style={{ textAlign: 'right' }}>
          <SyncStatus copyTradeOrders={copyTradeOrders} />
        </td>
      </tr>
      {isOpen && (
        <tr>
          <td
            style={{
              height: 0,
              padding: 0,
            }} colSpan={6}
          >
            <ListSubheader sx={{ padding: 1 }} variant="soft">
              Signal details
            </ListSubheader>
            <Sheet
              variant="soft"
              sx={{
                p: 1,
                boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)',
                display: 'flex',
                gap: 1,
              }}
            >
              <ExtraInfoCard title="Date" description={formattedDate} />
              <ExtraInfoCard title="At price" description={markPrice} />
              <ExtraInfoCard title="Amt change" description={amountChange.toFixed(2)} />
              <ExtraInfoCard title="Amt" description={amount} />
              <ExtraInfoCard title="PnL" description={pnl} descriptionColor={isProfitable ? 'success' : 'danger'} />
              <ExtraInfoCard title="ROE" description={formattedRoe} descriptionColor={isProfitable ? 'success' : 'danger'} />
            </Sheet>
            {copyTradeOrders.length > 0 && (
              <>
                <ListSubheader sx={{ padding: 1 }} variant="soft">
                  Copy trade orders
                </ListSubheader>
                <List
                  variant="soft"
                  sx={{
                    minWidth: 240,
                    borderRadius: 'sm',
                    gap: 1,
                  }}
                >
                  {copyTradeOrders.map(copyTradeOrder => <CopyTraderOrderItem key={copyTradeOrder.id} copyTradeOrder={copyTradeOrder} />)}
                </List>
              </>
            )}
          </td>
        </tr>
      )}
    </>
  );
});

export const CopyTraderOrderItem = observer(({ copyTradeOrder }: { copyTradeOrder: CopyTradeOrder }) => (
  <ListItem
    variant="outlined" sx={{
      marginX: 1,
      paddingY: 1,
    }}
  >
    <ExtraInfoCard title="Account" description={copyTradeOrder.accountName} variant="soft" />
    <ExtraInfoCard title="Date" description={copyTradeOrder.formattedDate} variant="soft" />
    {copyTradeOrder.orderStatus && <ExtraInfoCard title="Order Status" description={copyTradeOrder.orderStatus} variant="soft" />}
    {copyTradeOrder.errorMessage && <ExtraInfoCard title="Error message" description={copyTradeOrder.errorMessage} variant="soft" />}
  </ListItem>
));

export const SyncStatus = observer(({ copyTradeOrders }: { copyTradeOrders: CopyTradeOrder[] }) => {
  if (copyTradeOrders.length === 0) {
    return <SyncDisabledOutlined color="disabled" style={{ opacity: 0.1 }} />;
  }

  if (copyTradeOrders.some(({ errorMessage }) => errorMessage)) {
    return <SyncDisabledOutlined color="warning" />;
  }

  return <SyncOutlined color="success" />;
});
