/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/StoreContext';

export const Symbol = observer(({ symbol, leverage, isLong }: { symbol: string; leverage: number, isLong: boolean }) => {
  const { tradeEvents } = useStore();
  return (
    <Box
      onClick={() => {
        tradeEvents.updateSymbolFilter(symbol);
        tradeEvents.fetchEvents(true);
      }}
      sx={theme => ({
        borderLeftColor: isLong ? theme.vars.palette.primary.mainChannel : theme.vars.palette.danger.mainChannel,
        borderLeftWidth: 2,
        borderLeftStyle: 'solid',
        paddingLeft: 1,
        cursor: 'pointer',
      })}
    >
      <Typography level="body-xs">
        {symbol}
      </Typography>
      <Typography color={isLong ? 'success' : 'danger'} level="body-xs">
        {leverage}
        x
      </Typography>
    </Box>
  );
});
