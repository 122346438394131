import { EventType } from '@core/models';
import { uniqBy } from 'lodash';
import { computed } from 'mobx';
import {
  ExtendedModel,
  _await,
  fromSnapshot,
  getRoot,
  getSnapshot,
  idProp,
  model,
  modelAction,
  modelFlow,
  prop,
  tProp,
  types,
} from 'mobx-keystone';
import { RootStore } from 'store/root-store';
import { MainModel } from 'store/utils/base-model';
import { formatDate, formatDays } from 'utils/formatDate';
import { formatDecimalisedPercentage } from 'utils/formatPercentage';
import { FollowedTrader } from '../leaderboard/followed-trader';

@model('copy-trade-order')
export class CopyTradeOrder extends ExtendedModel(MainModel, {
  id: idProp,
  createdAt: prop<string>(),
  errorMessage: prop<string | null>(),
  orderStatus: prop<string | null>(),
  qty: prop<string | null>(),
  riskAllocation: prop<string | null>(),
  accountName: prop<string>(),
}) {
  @computed
  get formattedDate(){
    return formatDate(this.createdAt);
  }
}

@model('trade-event')
export class TradeEvent extends ExtendedModel(MainModel, {
  id: idProp,
  createdAt: prop<string>(),
  type: prop<EventType>(),
  symbol: prop<string>(),
  entryPrice: prop<number>(),
  markPrice: prop<number>(),
  pnl: prop<number>(),
  roe: prop<number>(),
  amount: prop<number>(),
  previousAmount: prop<number>(),
  updateTimeStamp: prop<number>(),
  tradeBefore: prop<boolean>(),
  long: prop<boolean>(),
  leverage: prop<number>(),
  trader: tProp(types.model(FollowedTrader)),
  amountChange: prop<number>(),
  percentageChange: prop<string>(),
  isProfitable: prop<boolean>(),
  copyTradeOrders: tProp(types.array(types.model(CopyTradeOrder))),
}) {
  @computed
  get formattedDate(){
    return formatDate(this.createdAt);
  }

  @computed
  get formattedRoe() {
    return formatDecimalisedPercentage(this.roe);
  }
}

@model('filter-chip')
export class FilterChip extends ExtendedModel(MainModel, {
  name: tProp(types.string),
  isSelected: tProp(types.boolean),
}) {
  @modelAction
  toggle() {
    this.isSelected = !this.isSelected;
  }
}

@model('trade-events')
export class TradeEvents extends ExtendedModel(MainModel, {
  events: tProp(types.array(types.model(TradeEvent)), () => []),
  count: tProp(types.number),
  take: tProp(types.number, 25),
  skip: tProp(types.number, 0),
  eventTypeFilters: tProp(types.array(FilterChip)),
  symbolFilter: tProp(types.string, ''),
}) {
  private get eventTypeFilterParams() {
    return this.eventTypeFilters
      .filter(({ isSelected }) => isSelected)
      .reduce((acc, { name }) => acc === '' ? name : `${name},${acc}`, '');
  }

  private get traderFilterParams() {
    const { leaderboard: { followedTraders } } = getRoot<RootStore>(this);

    return followedTraders
      .filter(({ isSelected }) => isSelected)
      .reduce((acc, { name }) => acc === '' ? name : `${name},${acc}`, '');
  }

  @modelAction
  updateSymbolFilter(symbolFilter: string) {
    this.symbolFilter = symbolFilter;
  }

  @modelFlow
  *fetchEvents(isFresh?: boolean) {
    if (isFresh) {
      this.skip = 0;
      this.take = 25;
    }

    const { take, skip } = this;

    const { data, count } = yield* _await(this.api.fetch('trade-events', {
      take,
      skip,
      type: this.eventTypeFilterParams,
      trader: this.traderFilterParams,
      symbol: this.symbolFilter,
    }));

    if (isFresh) {
      this.events = fromSnapshot(data);
    } else {
      this.events = fromSnapshot([
        ...getSnapshot(this.events),
        ...data,
      ]);
    }
    this.skip = this.skip + this.take;
    this.count = count;
  }

  @computed
  get hasMore() {
    return this.count >= this.events.length;
  }

  @computed
  get eventsByDay(): [string, TradeEvent[]][] {
    const days = new Map<string, TradeEvent[]>();

    this.events.forEach(event => {
      const dateString = formatDays(event.createdAt);

      if (days.has(dateString)) {
        days.get(dateString)?.push(event);
      } else {
        days.set(dateString, [event]);
      }
    });

    return Array
      .from(days)
      .map(([day, rawDateList]) => [
        day,
        uniqBy(rawDateList, ({ id }) => id)
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
      ]);
  }
}
