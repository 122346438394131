import { Check } from '@mui/icons-material';
import { Box, Button, Card, Chip, Divider, Input, Typography } from '@mui/joy';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useState, useEffect } from 'react';
import { useStore } from 'store/StoreContext';

export const TradeEventFilterTray = observer(() => (
  <>
    <EventTypeFilter />
    <TraderFilter />
    <SymbolFilter />
  </>
));

export const SymbolFilter = observer(() => {
  const { tradeEvents } = useStore();
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(isEmpty(tradeEvents.symbolFilter));
  }, [tradeEvents.symbolFilter]);

  const sendButton = (
    <Button
      disabled={isDisabled}
      onClick={async () => {
        await tradeEvents.fetchEvents(true);
        setDisabled(true);
      }}
    >
      Update
    </Button>
  );

  const clearButton = (
    <Button
      color="neutral"
      disabled={tradeEvents.symbolFilter === ''}
      onClick={async () => {
        await tradeEvents.updateSymbolFilter('');
        setDisabled(true);
        await tradeEvents.fetchEvents(true);
      }}
    >
      Clear
    </Button>
  );

  return (
    <Card>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Symbol filter</Typography>
      </Box>
      <Divider />
      <Input
        type="text"
        value={tradeEvents.symbolFilter}
        onChange={e => {
          const text = e.currentTarget.value;
          tradeEvents.updateSymbolFilter(text);
        }}
        startDecorator={clearButton}
        endDecorator={sendButton}
      />
    </Card>
  );
});

export const EventTypeFilter = observer(() => {
  const {
    tradeEvents: { eventTypeFilters },
    tradeEvents,
  } = useStore();

  return (
    <Card>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Event type filter</Typography>
      </Box>
      <Divider />
      {eventTypeFilters.map(filterChip => (
        <FilterChip
          key={filterChip.name}
          label={filterChip.name}
          isSelected={filterChip.isSelected}
          onClick={() => {
            filterChip.toggle();
            tradeEvents.fetchEvents(true);
          }}
        />
      ))}
    </Card>
  );
});

export const TraderFilter = observer(() => {
  const {
    leaderboard: { activeTraders },
    tradeEvents,
  } = useStore();

  return (
    <Card>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Trader filter</Typography>
      </Box>
      <Divider />
      {activeTraders.map(trader => (
        <FilterChip
          key={trader.id}
          label={trader.name}
          isSelected={trader.isSelected}
          onClick={() => {
            trader.toggle();
            tradeEvents.fetchEvents(true);
          }}
        />
      ))}
    </Card>
  );
});

export const FilterChip = ({ label, isSelected, onClick }: PropsWithChildren<{
  label: string,
  isSelected?: boolean,
  onClick: () => void
}>) => (
  <Chip
    variant="outlined"
    color="neutral"
    size="lg"
    endDecorator={isSelected ? <Check /> : <></>}
    onClick={() => onClick()}
  >
    {label}
  </Chip>
);
